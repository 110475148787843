import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
	Home,
	Contact,
	Login,
	Register,
	Reset,
	Jackjrs,
	Console,
	ProjectRequest,
	ProjectRequestDetails,
	ProjectRequestSuccess,
	ProjectOrderDetails,
	ProjectOrderHistory,
	PidsHome,
	UserProfile,
	ReviewProducts,
	Projects,
	PartID,
	NotFound,
	Landing,
} from './pages';
import { Footer, Header, JackjrsOnlyRoute, StackDetails } from './components';
// import SampleData from './components/sampleData/SampleData';
// import SamplePricing from './components/samplePricing/SamplePricing';
// import SampleInterchange from './components/sampleInterchange/SampleInterchange';
// import StackSamples from './pages/stackSamples/StackSamples';
// import UserCollections from './pages/userCollections/UserCollections';
// import PartIDCardDetails from './components/partIDCard/partIDCardDetails/PartIDCardDetails';

function App() {
	const user = 'null';

	return (
		<div className='app'>
			<BrowserRouter>
				<ToastContainer />
				{!user ? (
					<Login />
				) : (
					<>
						<Header />
						<Routes>
							<Route path='/' element={<Landing />} />
							<Route path='/home' element={<Home />} />
							<Route path='/pids' element={<PidsHome />} />
							{/* <Route path='/stack-samples' element={<StackSamples />} /> */}
							<Route path='/console' element={<Console />} />
							<Route path='/contact' element={<Contact />} />
							<Route path='/login' element={<Login />} />
							<Route path='/register' element={<Register />} />
							<Route path='/reset' element={<Reset />} />
							<Route path='/profile' element={<UserProfile />} />

							<Route
								path='/jackjrs/*'
								element={
									<JackjrsOnlyRoute>
										<Jackjrs />
									</JackjrsOnlyRoute>
								}
							/>

							{/* <Route
								path='/user-collections/:id'
								element={<UserCollections />}
							/> */}

							<Route path='/stack-details/:id' element={<StackDetails />} />

							{/* <Route path='/sample--download' element={<SampleData />} />
							<Route path='/sample--pricing' element={<SamplePricing />} /> */}
							{/* <Route
								path='/sample--interchange'
								element={<SampleInterchange />}
							/> */}
							<Route path='/projects' element={<Projects />} />
							<Route path='/project-request' element={<ProjectRequest />} />
							<Route
								path='/project-request-success'
								element={<ProjectRequestSuccess />}
							/>
							<Route
								path='/project-request-details'
								element={<ProjectRequestDetails />}
							/>
							<Route
								path='/project-order-history'
								element={<ProjectOrderHistory />}
							/>
							<Route
								path='/project-order-details'
								element={<ProjectOrderDetails />}
							/>
							<Route
								path='/project-order-details/:id'
								element={<ProjectOrderDetails />}
							/>
							<Route path='/review-product/:id' element={<ReviewProducts />} />
							<Route path='/user-profile/' element={<UserProfile />} />
							{/* <Route path='/Part-IDs/:id' element={<PartID />} /> */}

							<Route path='*' element={<NotFound />} />
						</Routes>
						<Footer />
					</>
				)}
			</BrowserRouter>
		</div>
	);
}

export default App;
