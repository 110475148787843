import { collection, deleteDoc, doc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { db, storage } from '../../../firebase/config';
import Loader from '../../loader/Loader';
import styles from './CardsView.module.scss';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { deleteObject, ref } from 'firebase/storage';
import Notiflix from 'notiflix';
import { useDispatch, useSelector } from 'react-redux';

import { selectStacks, STORE_STACKS } from '../../../redux/slice/stackSlice';

import useFetchCollection from '../../../customHooks/useFetchCollection';

// import {
// 	FILTER_BY_SEARCH,
// 	selectFilteredStacks,
// } from '../../../redux/slice/filterSlice2';

import Search from '../../search/Search';
import Pagination from '../../pagination/Pagination';
import useFetchCollectionSub from '../../../customHooks/useFetchCollectionSub';
import {
	FILTER_BY_SEARCH,
	selectFilteredStacks,
} from '../../../redux/slice/filterCardSlice';

const CardsView = () => {
	const [search, setSearch] = useState('');
	const { data, isLoading } = useFetchCollection('CARDS-ALL');
	const stacks = useSelector(selectStacks);
	const filteredStacks = useSelector(selectFilteredStacks);

	// Pagination states
	const [currentPage, setCurrentPage] = useState(1);
	const [stacksPerPage] = useState(500);

	// Get Current Stacks
	const indexOfLastStack = currentPage * stacksPerPage;
	const indexOfFirstStack = indexOfLastStack - stacksPerPage;
	const currentStacks = filteredStacks.slice(
		indexOfFirstStack,
		indexOfLastStack
	);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(
			STORE_STACKS({
				stacks: data,
			})
		);
	}, [dispatch, data]);

	useEffect(() => {
		dispatch(FILTER_BY_SEARCH({ stacks, search }));
	}, [dispatch, stacks, search]);

	const confirmDelete = (id) => {
		Notiflix.Confirm.show(
			'Delete Card!!!',
			'You are about to delete this Card from the database',
			'Delete',
			'Cancel',
			function okCb() {
				deleteStack(id);
			},
			function cancelCb() {
				console.log('Delete Canceled');
			},
			{
				width: '320px',
				borderRadius: '3px',
				titleColor: 'orangered',
				okButtonBackground: 'orangered',
				cssAnimationStyle: 'zoom',
			}
		);
	};

	const deleteStack = async (id) => {
		try {
			await deleteDoc(doc(db, 'CARDS-ALL', id));

			toast.success('Card deleted successfully.');
		} catch (error) {
			toast.error(error.message);
		}
	};

	return (
		<>
			{isLoading && <Loader />}
			<div className={styles.table}>
				<h2>All Cards</h2>

				<div className={styles.search}>
					<p>
						<b>{stacks.length}</b> Card Collections Found
					</p>
					<p>{/* <b>{filteredStacks.length}</b> Filtered Found */}</p>
					<Search value={search} onChange={(e) => setSearch(e.target.value)} />
				</div>

				{stacks.length === 0 ? (
					<p>No card found.</p>
				) : (
					<table>
						<thead>
							<tr>
								<th>Name</th>
								<th>Ecomm</th>
								<th>Image</th>
								<th>Card Count</th>
								{/* <th>Ecomm Count</th> */}
								<th>ID</th>
								<th>Last Update:</th>
								<th>Actions</th>
							</tr>
						</thead>
						<tbody>
							{currentStacks.map((stack, index) => {
								const {
									cardName,
									ecommName,
									cardImage,
									id,
									countPids,
									cardID,
									editDate,
								} = stack;
								return (
									<tr key={id}>
										<td>{cardName}</td>
										<td>{ecommName}</td>
										<td>
											<Link to={`/jackjrs/all-cards/${cardID}`}>
												<img src={cardImage} alt={cardID} />
											</Link>
										</td>
										<td>{countPids}</td>
										<td>{id}</td>
										<td>{editDate}</td>
										<td className={styles.icons}>
											<Link to={`/jackjrs/add-card/${id}`}>
												<FaEdit size={20} color='green' />
											</Link>
											&nbsp;
											<FaTrashAlt
												size={18}
												color='red'
												onClick={() => confirmDelete(id)}
											/>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				)}
				<Pagination
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					stacksPerPage={stacksPerPage}
					// totalStacks={filteredStacks.length}
				/>
			</div>
		</>
	);
};

export default CardsView;
