import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import {
	CALCULATE_SUBTOTAL,
	CALCULATE_TOTAL_QUANTITY,
	selectProjectsItems,
	selectProjectsTotalAmount,
} from '../../redux/slice/projectsSlice';
import { selectEmail } from '../../redux/slice/authSlice';
import { selectComments } from '../../redux/slice/projectRequestSlice';
import { toast } from 'react-toastify';
import ProjectRequestForm from '../../components/projectRequestForm/ProjectRequestForm';

// const stripePromise = 'loadStripe(process.env.REACT_APP_STRIPE_PK)';

const ProjectRequest = () => {
	const [message, setMessage] = useState('Initializing Project Request...');
	const [clientSecret, setClientSecret] = useState('');

	const projectsItems = useSelector(selectProjectsItems);
	const totalAmount = useSelector(selectProjectsTotalAmount);
	const customerEmail = useSelector(selectEmail);

	// const shippingAddress = useSelector(selectShippingAddress);
	const comments = useSelector(selectComments);

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(CALCULATE_SUBTOTAL());
		dispatch(CALCULATE_TOTAL_QUANTITY());
	}, [dispatch, projectsItems]);

	const description = `Part IDs payment: email: ${customerEmail}, Amount: ${totalAmount}`;

	// useEffect(() => {
	// 	// http://localhost:4242/create-payment-intent
	// 	// https://ufosdemo.herokuapp.com/create-payment-intent
	// 	// Create PaymentIntent as soon as the page loads send to BACKEND
	// 	fetch('http://localhost:4242/create-payment-intent', {
	// 		method: 'POST',
	// 		headers: { 'Content-Type': 'application/json' },
	// 		body: JSON.stringify({
	// 			items: projectsItems,
	// 			userEmail: customerEmail,
	// 			comments: comments,
	// 			description,
	// 		}),
	// 	})
	// 		.then((res) => {
	// 			if (res.ok) {
	// 				return res.json();
	// 			}
	// 			return res.json().then((json) => Promise.reject(json));
	// 		})
	// 		.catch((error) => {
	// 			setMessage('Failed to initialize projectRequest');
	// 			toast.error('Something went wrong!!!');
	// 		});
	// }, []);

	return (
		<>
			<section>
				<div className='container'>
					{/* <h3 style={{ color: 'orange' }}>{message}</h3> */}
				</div>
			</section>
			<ProjectRequestForm />
		</>
	);
};

export default ProjectRequest;
