import styles from './StackDetails.module.scss';

import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import spinnerImg from '../../../assets/spinner.jpg';
import { GoCloudDownload } from 'react-icons/go';
import { MdPriceChange } from 'react-icons/md';
import { SiConvertio } from 'react-icons/si';
import { useDispatch, useSelector } from 'react-redux';
import {
	ADD_TO_PROJECTS,
	ADD_TO_PROJECTS_DOWNLOAD,
	ADD_TO_PROJECTS_INTERCHANGE,
	ADD_TO_PROJECTS_PRICEAVAIL,
	CALCULATE_TOTAL_QUANTITY,
	DECREASE_PROJECTS,
	selectProjectsItems,
} from '../../../redux/slice/projectsSlice';
import useFetchDocument from '../../../customHooks/useFetchDocument';
import useFetchCollection from '../../../customHooks/useFetchCollection';

import Card from '../../card/Card';
import StarsRating from 'react-star-rate';
import orlyLogo from '../../../assets/retailerLogos/orly-logo-full.svg';
import zoneLogo from '../../../assets/retailerLogos/zone-logo-full.svg';

import advaLogo from '../../../assets/retailerLogos/adva-logo-full.svg';
import napaLogo from '../../../assets/retailerLogos/napa_logo.png';

import { AddShoppingCart } from '@mui/icons-material';
// import { Divider, IconButton, Paper, Stack, Chip } from '@mui/material';
import Loader from '../../loader/Loader';
import useFetchCollectionLimits from '../../../customHooks/useFetchCollectionLimits';

const StackDetails = () => {
	const { id } = useParams();
	const [stack, setStack] = useState(null);
	const { data, isLoading } = useFetchCollectionLimits(id);
	// const projectsItems = useSelector(selectProjectsItems);

	const { document } = useFetchDocument('All--Cards', id);
	console.log(data);
	const [pageSize, setPageSize] = React.useState(10);
	const dispatch = useDispatch();

	const columns = [
		// {
		// 	field: 'uidNumber',
		// 	headerName: 'Details Link',
		// 	flex: 1.5,
		// 	renderCell: (params) => (
		// 		<Link to={`../Part-IDs/${document.ecommBadge}${params.value}`}>
		// 			Click for DETAILS...
		// 		</Link>
		// 	),
		// },
		{ field: 'partNumber', headerName: 'Part Number', flex: 1 },
		{ field: 'partName', headerName: 'Part Name', flex: 4 },
		{
			field: 'editDate',
			headerName: 'Update',
			type: 'date',
			valueGetter: ({ value }) => value && new Date(value),
			flex: 1,
		},
	];

	// const filteredReviews = data.filter((review) => review.stackID === id);

	// const projects = projectsItems.find((projects) => projects.id === id);
	// const isProjectsAdded = projectsItems.findIndex((projects) => {
	// 	return projects.id === id;
	// });

	useEffect(() => {
		setStack(document);
	}, [document]);

	const addToProjects = (stack) => {
		dispatch(ADD_TO_PROJECTS(stack));
		dispatch(CALCULATE_TOTAL_QUANTITY());
	};
	const addToProjectsPriceAvail = (stack) => {
		dispatch(ADD_TO_PROJECTS_PRICEAVAIL(stack));
		dispatch(CALCULATE_TOTAL_QUANTITY());
	};
	const addToProjectsInterchange = (stack) => {
		dispatch(ADD_TO_PROJECTS_INTERCHANGE(stack));
		dispatch(CALCULATE_TOTAL_QUANTITY());
	};
	const addToProjectsDownload = (stack) => {
		dispatch(ADD_TO_PROJECTS_DOWNLOAD(stack));
		dispatch(CALCULATE_TOTAL_QUANTITY());
	};

	const decreaseProjects = (stack) => {
		dispatch(DECREASE_PROJECTS(stack));
		dispatch(CALCULATE_TOTAL_QUANTITY());
	};

	function detectRetailer(ret, f1, f2, f3, f4) {
		if (ret === 'OReilly') {
			const retailerHome = 'www.oreillyauto.com';
			return retailerHome, f1;
		}
		if (ret === 'Napa') {
			const retailerHome = 'www.napaonline.com';
			return retailerHome, f2;
		}
		if (ret === 'Advance') {
			const retailerHome = 'shop.advanceautoparts.com';
			return retailerHome, f3;
		}
		const retailerHome = 'www.autozone.com';
		return retailerHome, f4;
	}

	return (
		<section>
			<div className={`container ${styles.stack}`}>
				<div className={styles.header}>
					<h2>Card Details</h2>
					<Link to='/home'>&larr; Back To Cards</Link>
				</div>
				{stack === null ? (
					<img src={spinnerImg} alt='Loading' style={{ width: '50px' }} />
				) : (
					<>
						<div className={styles.details}>
							<div className={styles.info}>
								<div className={styles.stacklogoorly}>
									<div className={styles.stacklogoorly}>
										<img src={stack.stackLogo} alt={stack.stackID} />
									</div>
								</div>
								<div className={styles.logo}>
									{detectRetailer(
										`${stack.ecommName}`,
										<img
											src={orlyLogo}
											alt='orlyLogo'
											style={{ width: '170px' }}
										/>,
										<img
											src={napaLogo}
											alt='napaLogo'
											style={{ width: '170px' }}
										/>,
										<img
											src={advaLogo}
											alt='advaLogo'
											style={{ width: '170px' }}
										/>,
										<img
											src={zoneLogo}
											alt='zoneLogo'
											style={{ width: '150px' }}
										/>
									)}
								</div>
								<h3 className={styles.stackCounts}>{stack.stackLabel}</h3>
								<p style={{ color: 'black' }}>{stack.editDate} updated</p>
								<h3 className={styles.stackCounts}>
									{stack.stackEdition} - Edition
								</h3>
								<p style={{ color: 'black' }}>
									{/* Identified {stack.stackCount} out of {stack.ecommCount} shown */}
								</p>
								<h4 className={styles.stackCounts}>
									Card Count : {stack.stackCount}
								</h4>
								<div>
									<p>
										<b style={{ color: 'darkblue' }}>Ecomm: </b>
										{stack.ecommName}
									</p>
									<p>
										<b style={{ color: 'darkblue' }}>Card ID: </b>
										{stack.stackID}
									</p>
									<p>
										<b style={{ color: 'darkblue' }}>Type: </b>
										{stack.stackType}
									</p>
									<p>
										<b style={{ color: 'darkblue' }}>Total IDs: </b>
										{stack.stackCount}
									</p>
								</div>
							</div>
							<div className={styles.down}>
								<Card cardClass={styles.card}>
									<div className={styles.proview}>
										<h3>Download Card</h3>
										<GoCloudDownload size={22} color='blue' />
										<h3 style={{ color: 'darkred' }}>
											{stack.ecommName} - {stack.stackLabel} -{' '}
											{stack.stackEdition}
										</h3>
										<h4 style={{ color: 'blue' }}>
											<b>{Number(`${stack.stackCount}`)}</b>Part IDs in Card
										</h4>
										<br />

										<button
											className='--btn --btn-success'
											onClick={() => addToProjectsDownload(stack)}
										>
											<AddShoppingCart
												sx={{
													color: 'darkblue',
													fontSize: 'x-large',
													// backgroundColor: 'dodgerblue',
													padding: '1px',
												}}
											/>{' '}
											<p>Request Card</p>
										</button>
										<p style={{ color: 'darkblue' }}>
											<small>Last updated: {stack.editDate}</small>
											<br />
											{/* <small>Ecomm Count (displayed): {stack.ecommCount}</small>
											<br />
											<small>UID Ecomm (unique): {stack.ecommUidCount}</small>
											<br />
											<small>Card Count (found): {stack.stackCount}</small>
											<br />
											<small></small> */}
											{/* <b>
												{Number(
													(stack.stackCount / stack.ecommUidCount) * 100
												).toFixed(0)}
											</b>
											% Collection Found */}
										</p>
									</div>
								</Card>
							</div>
						</div>
						<>
							{isLoading && <Loader />}
							<div className={styles.table}>
								<div className={styles.search}>
									<p>
										<b style={{ color: 'red' }}>Sample Card Data Below (25)</b>{' '}
										{stack.stackID}{' '}
									</p>
									{/* <Search value={search} onChange={(e) => setSearch(e.target.value)} /> */}
								</div>

								{data.length === 0 ? (
									<p>No card found.</p>
								) : (
									<table>
										<thead>
											<tr>
												<th>Ecomm</th>
												<th>Part IDs</th>
												<th>Part Number</th>
												<th>Name</th>
												<th>*Ref Price</th>
												{/* <th>Core Price</th> */}
												<th>Brand</th>
												<th>Category</th>
												{/* <th>URL</th> */}
												<th>Updated</th>
											</tr>
										</thead>
										<tbody>
											{data.map((stack, index) => {
												const {
													id,
													ecommName,
													uidNumber,
													partNumber,
													partName,
													retailPrice,
													corePrice,
													brand,
													category,
													partJack,
													editDate,
												} = stack;
												return (
													<tr key={id}>
														<td>{ecommName}</td>
														<td>{uidNumber}</td>
														<td>{partNumber}</td>
														<td>{partName}</td>
														<td>{retailPrice}</td>
														{/* <td>{corePrice}</td> */}
														<td>{brand}</td>
														<td>{category}</td>
														{/* <td>{shortenText(partJack, 35)}</td> */}
														<td>{editDate}</td>
													</tr>
												);
											})}
										</tbody>
									</table>
								)}
							</div>
						</>{' '}
					</>
				)}
			</div>
		</section>
	);
};

export default StackDetails;
