import { useState } from 'react';
import Card from '../../components/card/Card';
import styles from './ProjectRequestDetails.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { SAVE_COMMENTS } from '../../redux/slice/projectRequestSlice';
import { useNavigate } from 'react-router-dom';
import ProjectRequestSummary from '../../components/projectRequestSummary/ProjectRequestSummary';
import { selectUserID } from '../../redux/slice/authSlice';

const initialAddressState = {
	userEmail: '',
	comments: '',
};

const ProjectRequestDetails = () => {
	const userID = useSelector(selectUserID);
	const [comments, setComments] = useState({
		...initialAddressState,
	});

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleComments = (e) => {
		const { name, value } = e.target;
		setComments({
			...comments,
			[name]: value,
		});
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(SAVE_COMMENTS(comments));
		navigate('/project-request');
	};

	return (
		<section>
			<div className={`container ${styles.projectRequest}`}>
				<h2 style={{ color: 'orange' }}>
					Card Request: Additional Information
				</h2>
				<form onSubmit={handleSubmit}>
					<div>
						<Card cardClass={styles.card}>
							<h4 style={{ color: 'red' }}>
								We will email you a confirmation once your request is being
								processed.
							</h4>
							<br />
							<p>-Downloads will be available within 24-hrs.</p>
							<br />
							{/* <p>
								-Interchange and Pricing Reports, a Technician will contact you
								to complete the project request.
							</p>
							<label>
								Multiple locations can be selected. Note: Pricing is for one
								location of your choice.
							</label> */}
							<br />
							{/* <p>-Payment will be due on acceptance of the project.</p> */}
							<br />

							<input
								type='text'
								name='comments'
								placeholder='Any additional comments about your project...'
								value={comments.comments}
								onChange={(e) => handleComments(e)}
								cols='30'
								rows='10'
							/>

							<button type='submit' className='--btn --btn-primary'>
								Continue
							</button>
						</Card>
					</div>
					<div>
						<Card cardClass={styles.card}>
							<ProjectRequestSummary />
						</Card>
					</div>
				</form>
			</div>
		</section>
	);
};

export default ProjectRequestDetails;
