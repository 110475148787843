import React, { useEffect, useState } from 'react';
import {
	PaymentElement,
	useStripe,
	useElements,
} from '@stripe/react-stripe-js';
import styles from './ProjectRequestForm.module.scss';
import Card from '../card/Card';
import ProjectRequestSummary from '../projectRequestSummary/ProjectRequestSummary';
import spinnerImg from '../../assets/spinner.jpg';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { selectEmail, selectUserID } from '../../redux/slice/authSlice';
import {
	CLEAR_PROJECTS,
	selectProjectsItems,
	selectProjectsTotalAmount,
} from '../../redux/slice/projectsSlice';
import { selectComments } from '../../redux/slice/projectRequestSlice';
import { addDoc, collection, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { useNavigate } from 'react-router-dom';

const ProjectRequestForm = () => {
	const [message, setMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	// const stripe = useStripe();
	// const elements = useElements();

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const userID = useSelector(selectUserID);
	const userEmail = useSelector(selectEmail);
	const projectsItems = useSelector(selectProjectsItems);
	const projectsTotalAmount = useSelector(selectProjectsTotalAmount);
	const comments = useSelector(selectComments);
	const initialState = {
		createdAt: '',
		userID: '',
		userEmail: '',
		projectOrderDate: '',
		projectOrderTime: '',
		projectOrderAmount: '',
		projectOrderStatus: '',
		projectsItems: '',
		comments: '',
	};

	// Save projectOrder to ProjectOrder History
	const saveProjectOrder = () => {
		const today = new Date();
		const date = today.toDateString();
		const time = today.toLocaleTimeString();
		const projectOrderConfig = {
			userID,
			userEmail,
			projectOrderDate: date,
			projectOrderTime: time,
			projectOrderAmount: projectsTotalAmount,
			projectOrderStatus: 'Project Order Placed...',
			projectsItems,
			comments,
			createdAt: Timestamp.now().toDate(),
		};
		try {
			addDoc(collection(db, 'Project--orders'), projectOrderConfig);
			dispatch(CLEAR_PROJECTS());
			toast.success('Project Order saved');
			navigate('/project-request-success');
		} catch (error) {
			toast.error(error.message);
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setMessage(null);

		// if (!stripe || !elements) {
		// 	return;
		// }

		setIsLoading(true);

		const confirmPayment = confirmPayment({
			// elements,
			confirmParams: {
				// Make sure to change this to your payment completion page
				return_url: 'http://localhost:3000/project-request-success',
			},
			redirect: 'if_required',
		}).then(() => {
			toast.success('successful');
			saveProjectOrder();
		});

		setIsLoading(false);
	};

	return (
		<section>
			<div className={`container ${styles.projectRequest}`}>
				<h2>Project Request</h2>
				<form onSubmit={saveProjectOrder}>
					<div>
						<Card cardClass={styles.card}>
							<ProjectRequestSummary />
						</Card>
					</div>
					<div>
						<Card cardClass={`${styles.card} ${styles.pay}`}>
							<h3>Project Request</h3>
							{/* <PaymentElement id={styles['payment-element']} /> */}
							<button
								// disabled={isLoading || !stripe || !elements}
								id='submit'
								className={styles.button}
							>
								<span id='button-text'>
									{isLoading ? (
										<img
											src={spinnerImg}
											alt='Loading...'
											style={{ width: '20px' }}
										/>
									) : (
										'Send Request'
									)}
								</span>
							</button>
							{/* Show any error or success messages */}
							{message && <div id={styles['payment-message']}>{message}</div>}
						</Card>
					</div>
				</form>
			</div>
		</section>
	);
};

export default ProjectRequestForm;
