import { configureStore, combineReducers } from '@reduxjs/toolkit';
import authReducer from './slice/authSlice';
import filterReducer from './slice/filterSlice';
import stackReducer from './slice/stackSlice';
// import partidCardReducer from './slice/partidCardSlice';
import partidReducer from './slice/partidSlice';
import projectsReducer from './slice/projectsSlice';
import projectOrderReducer from './slice/projectOrderSlice';
import projectRequestReducer from './slice/projectRequestSlice';

const rootReducer = combineReducers({
	auth: authReducer,
	filter: filterReducer,
	stack: stackReducer,
	// partidCard: partidCardReducer,
	partid: partidReducer,
	projects: projectsReducer,
	projectOrder: projectOrderReducer,
	projectRequest: projectRequestReducer,
});

const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});

export default store;
