import React from 'react';
import Stack from '../../components/stack/Stack';

const Home = () => {
	return (
		<div>
			<Stack />
		</div>
	);
};

export default Home;
