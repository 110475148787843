import styles from './Chart.module.scss';
import React from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import Card from '../card/Card';
import { useSelector } from 'react-redux';
import { selectProjectOrderHistory } from '../../redux/slice/projectOrderSlice';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

export const options = {
	responsive: true,
	plugins: {
		legend: {
			position: 'top',
		},
		title: {
			display: false,
			text: 'Chart.js Bar Chart',
		},
	},
};

const Chart = () => {
	const projectOrders = useSelector(selectProjectOrderHistory);
	const array = [];

	projectOrders.map((item) => {
		const { projectOrderStatus } = item;
		array.push(projectOrderStatus);
	});
	const getProjectOrderCount = (arr, value) => {
		return arr.filter((n) => n === value).length;
	};

	const [q1, q2, q3, q4] = [
		'ProjectOrder Placed...',
		'Processing...',
		'Shipped...',
		'Delivered',
	];
	const placed = getProjectOrderCount(array, q1);
	const processing = getProjectOrderCount(array, q2);
	const shipped = getProjectOrderCount(array, q3);
	const delivered = getProjectOrderCount(array, q4);

	const data = {
		labels: ['Placed ProjectOrders', 'Processing', 'Shipped', 'Delivered'],
		datasets: [
			{
				label: 'ProjectOrder Count',
				data: [placed, processing, shipped, delivered],
				backgroundColor: 'rgba(235, 64, 52, 0.75)',
			},
		],
	};
	return (
		<div className={styles.charts}>
			<Card cardClass={styles.card}>
				<h3>ProjectOrder Status Chart</h3>
				<Bar options={options} data={data} />
			</Card>
		</div>
	);
};

export default Chart;
