import React, { useEffect, useState } from 'react';
import StackFilter from './stackFilter/StackFilter';
import StackList from './stackList/StackList';
import styles from './Stack.module.scss';
import useFetchCollection from '../../customHooks/useFetchCollection';
import { useDispatch, useSelector } from 'react-redux';
import { selectStacks, STORE_STACKS } from '../../redux/slice/stackSlice';
import spinnerImg from '../../assets/spinner.jpg';
import { FaCogs } from 'react-icons/fa';
// import useFetchCollectionSub from '../../customHooks/useFetchCollectionSub';

const Stack = () => {
	const { data, isLoading } = useFetchCollection('All--Cards');
	const [showFilter, setShowFilter] = useState(false);
	const stacks = useSelector(selectStacks);
	const dispatch = useDispatch();

	useEffect(() => {
		console.log('effect ran');
	}, []);

	useEffect(() => {
		dispatch(
			STORE_STACKS({
				stacks: data,
			})
		);
	}, [dispatch, data]);
	console.log(data);

	const toggleFilter = () => {
		setShowFilter(!showFilter);
	};

	return (
		<section>
			<div className={`container ${styles.stack}`}>
				<aside
					className={
						showFilter ? `${styles.filter} ${styles.show}` : `${styles.filter}`
					}
				>
					{isLoading ? null : <StackFilter />}
				</aside>
				<div className={styles.content}>
					{isLoading ? (
						<img
							src={spinnerImg}
							alt='Loading...'
							style={{ width: '50px' }}
							className='--center-all'
						/>
					) : (
						<StackList stacks={stacks} />
					)}
					<div className={styles.icon} onClick={toggleFilter}>
						<FaCogs size={20} color='red' />
						<p>
							<b>{showFilter ? 'Hide Filter' : 'Show Filter'} </b>
						</p>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Stack;
