import React from 'react';

const Console = () => {
	return (
		<div>
			<h2>CONSOLE</h2>
		</div>
	);
};

export default Console;
