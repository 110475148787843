import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectEmail } from '../../redux/slice/authSlice';

const JackjrsOnlyRoute = ({ children }) => {
	const userEmail = useSelector(selectEmail);

	if (userEmail === 'jackjrcode@gmail.com') {
		return children;
	}
	if (userEmail === 'dylan@partids.com') {
		return children;
	}
	if (userEmail === 'kalebjohnson45@yahoo.com') {
		return children;
	}
	return (
		<section style={{ height: '80vh' }}>
			<div className='container'>
				<h2>Permission Denied.</h2>
				<p>This page can only be view by an JackJrs Admin.</p>
				<br />
				<Link to='/'>
					<button className='--btn'>&larr; Back To Home</button>
				</Link>
			</div>
		</section>
	);
};

export const JackjrsOnlyLink = ({ children }) => {
	const userEmail = useSelector(selectEmail);

	if (userEmail === 'jackjrcode@gmail.com') {
		return children;
	}
	if (userEmail === 'dylan@partids.com') {
		return children;
	}
	if (userEmail === 'kalebjohnson45@yahoo.com') {
		return children;
	}
	return null;
};

export default JackjrsOnlyRoute;
