import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const initialState = {
	projectsItems: localStorage.getItem('projectsItems')
		? JSON.parse(localStorage.getItem('projectsItems'))
		: [],
	projectsTotalQuantity: 1,
	projectsTotalAmount: 0,
	previousURL: '',
};

const projectsSlice = createSlice({
	name: 'projects',
	initialState,
	reducers: {
		ADD_TO_PROJECTS_INTERCHANGE(state, action) {
			console.log('interchange', action.payload);

			const tempStackInterchange = {
				...action.payload,
				projectsQuantity: 1,
				projectType: 'Interchange',
				stackPrice: action.payload.interchangePrice,
			};
			state.projectsItems.push(tempStackInterchange);
			console.log('tempStackInterchange', tempStackInterchange);
			toast.success(`${action.payload.stackID} added to projects`, {
				position: 'top-left',
			});
			// save projects to LS
			localStorage.setItem(
				'projectsItems',
				JSON.stringify(state.projectsItems)
			);
		},
		ADD_TO_PROJECTS_PRICEAVAIL(state, action) {
			console.log('priceavail', action.payload);

			const tempStackPriceAvail = {
				...action.payload,
				projectsQuantity: 1,
				projectType: 'Price & Avail',
				stackPrice: action.payload.priceAvailPrice,
			};
			state.projectsItems.push(tempStackPriceAvail);
			toast.success(`${action.payload.stackID} added to projects`, {
				position: 'top-left',
			});
			// save projects to LS
			localStorage.setItem(
				'projectsItems',
				JSON.stringify(state.projectsItems)
			);
		},
		ADD_TO_PROJECTS_DOWNLOAD(state, action) {
			console.log('download', action.payload);
			const tempStackDownload = {
				...action.payload,
				projectsQuantity: 1,
				projectType: 'Download',
				stackPrice: action.payload.downloadPrice,
			};
			state.projectsItems.push(tempStackDownload);
			toast.success(`${action.payload.stackID} added to projects`, {
				position: 'top-left',
			});
			// }
			// save projects to LS
			localStorage.setItem(
				'projectsItems',
				JSON.stringify(state.projectsItems)
			);
		},
		ADD_TO_PROJECTS(state, action) {
			//   console.log(action.payload);
			const stackIndex = state.projectsItems.findIndex(
				(item) => item.id === action.payload.id
			);

			if (stackIndex >= 0) {
				// Item already exists in the projects
				// Increase the projectsQuantity
				// state.projectsItems[stackIndex].projectsQuantity += 1;
				toast.info(`${action.payload.stackID} increased by one`, {
					position: 'top-left',
				});
			} else {
				// Item doesn't exists in the projects
				// Add item to the projects
				const tempStack = { ...action.payload, projectsQuantity: 1 };
				state.projectsItems.push(tempStack);
				toast.success(`${action.payload.stackID} added to projects`, {
					position: 'top-left',
				});
			}
			// save projects to LS
			localStorage.setItem(
				'projectsItems',
				JSON.stringify(state.projectsItems)
			);
		},
		DECREASE_PROJECTS(state, action) {
			console.log(action.payload);
			const stackIndex = state.projectsItems.findIndex(
				(item) => item.id === action.payload.id
			);

			if (state.projectsItems[stackIndex].projectsQuantity > 1) {
				state.projectsItems[stackIndex].projectsQuantity -= 1;
				toast.info(`${action.payload.stackID} decreased by one`, {
					position: 'top-left',
				});
			} else if (state.projectsItems[stackIndex].projectsQuantity === 1) {
				const newProjectsItem = state.projectsItems.filter(
					(item) => item.id !== action.payload.id
				);
				state.projectsItems = newProjectsItem;
				toast.success(`${action.payload.stackID} removed from projects`, {
					position: 'top-left',
				});
			}
			localStorage.setItem(
				'projectsItems',
				JSON.stringify(state.projectsItems)
			);
		},
		REMOVE_FROM_PROJECTS(state, action) {
			const newProjectsItem = state.projectsItems.filter(
				(item) => item.id !== action.payload.id
			);

			state.projectsItems = newProjectsItem;
			toast.success(`${action.payload.stackID} removed from projects`, {
				position: 'top-left',
			});

			localStorage.setItem(
				'projectsItems',
				JSON.stringify(state.projectsItems)
			);
		},
		CLEAR_PROJECTS(state, action) {
			state.projectsItems = [];
			toast.info(`Projects cleared`, {
				position: 'top-left',
			});

			localStorage.setItem(
				'projectsItems',
				JSON.stringify(state.projectsItems)
			);
		},

		CALCULATE_SUBTOTAL_DOWNLOAD(state, action) {
			const array = [];
			state.projectsItems.map((item) => {
				const { projectsQuantity, stackCount } = item;
				const stackPriceDownload = stackCount * 1;
				const projectsItemAmountDownload =
					stackPriceDownload * projectsQuantity;
				return array.push(projectsItemAmountDownload);
			});
			const totalAmountDownload = array.reduce((a, b) => {
				return a + b;
			}, 0);
			state.projectsTotalAmountDownload = totalAmountDownload;
		},

		CALCULATE_SUBTOTAL(state, action) {
			const array = [];
			state.projectsItems.map((item) => {
				const { projectsQuantity, stackPrice } = item;
				// const stackPrice = stackCount * 0.009;
				const projectsItemAmount = stackPrice * projectsQuantity;
				return array.push(projectsItemAmount);
			});
			const totalAmount = array.reduce((a, b) => {
				return a + b;
			}, 0);
			state.projectsTotalAmount = totalAmount;
		},

		CALCULATE_TOTAL_QUANTITY(state, action) {
			const array = [];
			state.projectsItems.map((item) => {
				const { projectsQuantity } = item;
				const quantity = projectsQuantity;
				return array.push(quantity);
			});
			const totalQuantity = array.reduce((a, b) => {
				return a + b;
			}, 0);
			state.projectsTotalQuantity = totalQuantity;
		},

		SAVE_URL(state, action) {
			// console.log(action.payload);
			state.previousURL = action.payload;
		},
	},
});

export const {
	ADD_TO_PROJECTS,
	ADD_TO_PROJECTS_DOWNLOAD,
	ADD_TO_PROJECTS_INTERCHANGE,
	ADD_TO_PROJECTS_PRICEAVAIL,
	DECREASE_PROJECTS,
	REMOVE_FROM_PROJECTS,
	CLEAR_PROJECTS,
	CALCULATE_SUBTOTAL,
	CALCULATE_SUBTOTAL_DOWNLOAD,
	CALCULATE_TOTAL_QUANTITY,
	SAVE_URL,
} = projectsSlice.actions;

export const selectProjectsItems = (state) => state.projects.projectsItems;

export const selectProjectsTotalQuantity = (state) =>
	state.projects.projectsTotalQuantity;

export const selectProjectsTotalAmount = (state) =>
	state.projects.projectsTotalAmount;
export const selectProjectsTotalAmountDownload = (state) =>
	state.projects.projectsTotalAmountDownload;

export const selectPreviousURL = (state) => state.projects.previousURL;

export default projectsSlice.reducer;
