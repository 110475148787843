import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	stacks: [],
};

const stackSlice = createSlice({
	name: 'stack',
	initialState,
	reducers: {
		STORE_STACKS(state, action) {
			state.stacks = action.payload.stacks;
			// console.log(action.payload.stacks);
		},
		GET_PROJECT_LIST(state, action) {
			const { stacks } = action.payload;
		},
	},
});

export const { STORE_STACKS } = stackSlice.actions;

export const selectStacks = (state) => state.stack.stacks;

export default stackSlice.reducer;
