import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { selectUserName } from '../../../redux/slice/authSlice';
import styles from './Navbar.module.scss';
import { GiBeamSatellite } from 'react-icons/gi';

const activeLink = ({ isActive }) => (isActive ? `${styles.active}` : '');

const Navbar = () => {
	const userName = useSelector(selectUserName);

	return (
		<div className={styles.navbar}>
			<div className={styles.user}>
				<GiBeamSatellite size={40} color='#fff' />
				<h4>{userName}</h4>
			</div>
			<nav>
				<ul>
					<li>
						<NavLink to='/jackjrs/dash' className={activeLink}>
							Part IDs Dash
						</NavLink>
					</li>
					<li>
						<NavLink to='/jackjrs/add-card/ADD' className={activeLink}>
							Add Card
						</NavLink>
					</li>
					<li>
						<NavLink to='/jackjrs/all-cards' className={activeLink}>
							View Cards
						</NavLink>
					</li>

					<li>
						<NavLink to='/jackjrs/card-orders' className={activeLink}>
							Card Orders
						</NavLink>
					</li>
					<li>
						<NavLink to='/jackjrs/ufosbots' className={activeLink}>
							UFOS Bots
						</NavLink>
					</li>
				</ul>
			</nav>
		</div>
	);
};

export default Navbar;
