import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	FILTER_BY_ECOMMNAME,
	FILTER_BY_STACKTYPE,
	FILTER_BY_STACKID,
	FILTER_BY_STACKGROUP,
	FILTER_BY_PRICE,
} from '../../../redux/slice/filterSlice';
import {
	selectStacks,
	selectMaxPrice,
	selectMinPrice,
} from '../../../redux/slice/stackSlice';
import styles from './StackFilter.module.scss';

const StackFilter = () => {
	const [ecommName, setEcommName] = useState('All');
	const [stackID, setStackID] = useState('All');
	const [stackType, setStackType] = useState('All');
	const [stackGroup, setStackGroup] = useState('All');
	// const [retailPrice, setRetailPrice] = useState(500);

	const stacks = useSelector(selectStacks);
	// const minPrice = useSelector(selectMinPrice);
	// const maxPrice = useSelector(selectMaxPrice);

	const dispatch = useDispatch();

	const allEcommNames = [
		'All',
		...new Set(stacks.map((stack) => stack.ecommName)),
	];

	// const allStackID = ['All', ...new Set(stacks.map((stack) => stack.stackID))];

	const allStackType = [
		'All',
		...new Set(stacks.map((stack) => stack.stackType)),
	];
	const allStackGroup = [
		'All',
		...new Set(stacks.map((stack) => stack.stackGroup)),
	];

	useEffect(() => {
		dispatch(FILTER_BY_STACKID({ stacks, stackID }));
	}, [dispatch, stacks, stackID]);

	useEffect(() => {
		dispatch(FILTER_BY_STACKTYPE({ stacks, stackType }));
	}, [dispatch, stacks, stackType]);

	useEffect(() => {
		dispatch(FILTER_BY_STACKGROUP({ stacks, stackGroup }));
	}, [dispatch, stacks, stackGroup]);

	const filterStacks = (ret) => {
		setEcommName(ret);
		dispatch(
			FILTER_BY_ECOMMNAME({
				stacks,
				ecommName: ret,
			})
		);
	};

	const clearFilters = () => {
		setEcommName('All');
		setStackGroup('All');
		setStackType('All');
		// setRetailPrice(maxPrice);
	};

	return (
		<div className={styles.filter}>
			<h4>Retailers</h4>
			<div className={styles.ecommName}>
				{allEcommNames.map((ret, index) => {
					return (
						<button
							key={index}
							type='button'
							className={`${ecommName}` === ret ? `${styles.active}` : null}
							onClick={() => filterStacks(ret)}
						>
							&#8250; {ret}
						</button>
					);
				})}
			</div>
			<h4>Collection Groups</h4>
			<div className={styles.ecommName}>
				{allStackGroup.map((ret, index) => {
					return (
						<button
							key={index}
							type='button'
							className={`${stackGroup}` === ret ? `${styles.active}` : null}
							onClick={() => setStackGroup(ret)}
						>
							&#8250; {ret}
						</button>
					);
				})}
			</div>

			{/* <h4>Collections</h4>
			<div className={styles.stackType}>
				<select value={stackID} onChange={(e) => setStackID(e.target.value)}>
					{allStackID.map((cname, index) => {
						return (
							<option key={index} value={cname}>
								{cname}
							</option>
						);
					})}
				</select>
			</div> */}

			<h4>Collection Type</h4>
			<div className={styles.stackType}>
				<select
					value={stackType}
					onChange={(e) => setStackType(e.target.value)}
				>
					{allStackType.map((bname, index) => {
						return (
							<option key={index} value={bname}>
								{bname}
							</option>
						);
					})}
				</select>

				{/* <p>$ {retailPrice}</p>
				<h4>Retail Price</h4>
				<div className={styles.retailPrice}>
					<input
						type='range'
						value={retailPrice}
						onChange={(e) => setRetailPrice(e.target.value)}
						min={minPrice}
						max={maxPrice}
					/>
				</div> */}
				<br />
				<button className='--btn --btn-danger' onClick={clearFilters}>
					Clear Filter
				</button>
			</div>
		</div>
	);
};

export default StackFilter;
