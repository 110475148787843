import styles from './UserProfile.module.scss';

import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import spinnerImg from '../../assets/spinner.jpg';

import { CgSearchFound } from 'react-icons/cg';
import { GiRadarSweep } from 'react-icons/gi';
import { GoCloudDownload } from 'react-icons/go';
import { MdPriceChange } from 'react-icons/md';
import { SiConvertio } from 'react-icons/si';

import { useDispatch, useSelector } from 'react-redux';

import useFetchDocument from '../../customHooks/useFetchDocument';
import useFetchCollection from '../../customHooks/useFetchCollection';

import Card from '../../components/card/Card';
import StarsRating from 'react-star-rate';
import orlyLogo from '../../assets/retailerLogos/orly-logo-full.svg';
import zoneLogo from '../../assets/retailerLogos/zone-logo-full.svg';
import { AddShoppingCart } from '@mui/icons-material';
import { Divider, IconButton, Paper, Stack, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';

import {
	DataGrid,
	GridToolbar,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { Box } from '@mui/system';

const UserProfile = () => {
	const { id } = useParams();
	const [stack, setStack] = useState(null);
	const dispatch = useDispatch();
	// const projectsItems = useSelector(selectProjectsItems);
	// const { document } = useFetchDocument('CARDS', id);
	// const { data, isLoading } = useFetchCollection(id);
	const [pageSize, setPageSize] = React.useState(10);

	useEffect(() => {
		setStack(document);
	}, [document]);

	function detectRetailer(ret, f1, f2, f3, f4) {
		if (ret === 'OReilly') {
			const retailerHome = 'www.oreillyauto.com';
			return retailerHome, f1;
		}
		if (ret === 'Napa') {
			const retailerHome = 'www.napaonline.com';
			return retailerHome, f2;
		}
		if (ret === 'Advance') {
			const retailerHome = 'shop.advanceautoparts.com';
			return retailerHome, f3;
		}
		const retailerHome = 'www.autozone.com';
		return retailerHome, f4;
	}

	return (
		<section>
			<div className={`container ${styles.stack}`}>
				<div className={styles.header}>
					<h2>User Profile</h2>
					<Link to='/home'>&larr; Back To Stacks</Link>
				</div>
				{stack === null ? (
					<img src={spinnerImg} alt='Loading' style={{ width: '50px' }} />
				) : (
					<></>
				)}
			</div>
		</section>
	);
};

export default UserProfile;
