import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	filteredStacks: [],
};

const filterSlice = createSlice({
	name: 'filter',
	initialState,
	reducers: {
		FILTER_BY_SEARCH(state, action) {
			const { stacks, search } = action.payload;
			const tempStacks = stacks.filter((stack) =>
				stack.stackLabel.toLowerCase().includes(search.toLowerCase())
			);

			state.filteredStacks = tempStacks;
		},

		SORT_STACKS(state, action) {
			const { stacks, sort } = action.payload;
			let tempStacks = [];
			if (sort === 'latest') {
				tempStacks = stacks;
			}
			if (sort === 'lowest-price') {
				tempStacks = stacks.slice().sort((a, b) => {
					return a.retailPrice - b.retailPrice;
				});
			}
			if (sort === 'highest-price') {
				tempStacks = stacks.slice().sort((a, b) => {
					return b.retailPrice - a.retailPrice;
				});
			}
			if (sort === 'a-z') {
				tempStacks = stacks.slice().sort((a, b) => {
					return a.stackLabel.localeCompare(b.stackLabel);
				});
			}
			if (sort === 'z-a') {
				tempStacks = stacks.slice().sort((a, b) => {
					return b.stackLabel.localeCompare(a.stackLabel);
				});
			}
			state.filteredStacks = tempStacks;
		},

		FILTER_BY_ECOMMNAME(state, action) {
			const { stacks, ecommName } = action.payload;
			let tempStacks = [];
			if (ecommName === 'All') {
				tempStacks = stacks;
			} else {
				tempStacks = stacks.filter((stack) => stack.ecommName === ecommName);
			}
			state.filteredStacks = tempStacks;
		},
		FILTER_BY_STACKGROUP(state, action) {
			const { stacks, stackGroup } = action.payload;
			let tempStacks = [];
			if (stackGroup === 'All') {
				tempStacks = stacks;
			} else {
				tempStacks = stacks.filter((stack) => stack.stackGroup === stackGroup);
			}
			state.filteredStacks = tempStacks;
		},
		FILTER_BY_STACKID(state, action) {
			const { stacks, stackID } = action.payload;
			let tempStacks = [];
			if (stackID === 'All') {
				tempStacks = stacks;
			} else {
				tempStacks = stacks.filter((stack) => stack.stackID === stackID);
			}
			state.filteredStacks = tempStacks;
		},
		FILTER_BY_STACKTYPE(state, action) {
			const { stacks, stackType } = action.payload;
			let tempStacks = [];
			if (stackType === 'All') {
				tempStacks = stacks;
			} else {
				tempStacks = stacks.filter((stack) => stack.stackType === stackType);
			}
			state.filteredStacks = tempStacks;
		},
		FILTER_BY_PRICE(state, action) {
			const { stacks, price } = action.payload;
			let tempStacks = [];

			tempStacks = stacks.filter((stack) => stack.price <= price);

			state.filteredStacks = tempStacks;
		},
	},
});

export const {
	FILTER_BY_SEARCH,
	SORT_STACKS,
	FILTER_BY_ECOMMNAME,
	FILTER_BY_STACKID,
	FILTER_BY_STACKTYPE,
	FILTER_BY_STACKGROUP,
	FILTER_BY_PRICE,
} = filterSlice.actions;

export const selectFilteredStacks = (state) => state.filter.filteredStacks;

export default filterSlice.reducer;
