import { collection, doc, setDoc, Timestamp } from 'firebase/firestore';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { db } from '../../../firebase/config';
import Card from '../../card/Card';
import Loader from '../../loader/Loader';
import styles from './ChangeProjectOrderStatus.module.scss';

const ChangeProjectOrderStatus = ({ projectOrder, id }) => {
	const [status, setStatus] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();

	const editProjectOrder = (e, id) => {
		e.preventDefault();
		setIsLoading(true);

		const projectOrderConfig = {
			userID: projectOrder.userID,
			userEmail: projectOrder.userEmail,
			projectOrderDate: projectOrder.projectOrderDate,
			projectOrderTime: projectOrder.projectOrderTime,
			projectOrderAmount: projectOrder.projectOrderAmount,
			projectOrderStatus: status,
			projectsItems: projectOrder.projectsItems,
			comments: projectOrder.comments,
			createdAt: projectOrder.createdAt,
			editedAt: Timestamp.now().toDate(),
		};
		try {
			setDoc(doc(db, 'Project--orders', id), projectOrderConfig);

			setIsLoading(false);
			toast.success('Project Order status changes successfully');
			navigate('/jackjrs/project-orders');
		} catch (error) {
			setIsLoading(false);
			toast.error(error.message);
		}
	};

	return (
		<>
			{isLoading && <Loader />}

			<div className={styles.status}>
				<Card cardClass={styles.card}>
					<h4>Update Status</h4>
					<form onSubmit={(e) => editProjectOrder(e, id)}>
						<span>
							<select
								value={status}
								onChange={(e) => setStatus(e.target.value)}
							>
								<option value='' disabled>
									-- Choose one --
								</option>
								<option value='ProjectOrder Placed...'>
									ProjectOrder Placed...
								</option>
								<option value='Processing...'>Processing...</option>
								<option value='Ready'>Ready</option>
							</select>
						</span>
						<span>
							<button type='submit' className='--btn --btn-primary'>
								Update Status
							</button>
						</span>
					</form>
				</Card>
			</div>
		</>
	);
};

export default ChangeProjectOrderStatus;
