import React from 'react';
import PidsCard from '../../components/pidsCard/PidsCard';

const PidsHome = () => {
	return (
		<div>
			<PidsCard />
		</div>
	);
};

export default PidsHome;
