import React from 'react';
import { Link } from 'react-router-dom';

const ProjectRequestSuccess = () => {
	return (
		<section>
			<div className='container'>
				<h2 style={{ color: 'orange' }}>Project Request Successful</h2>
				<p style={{ color: 'white' }}>
					Thank you for you request, we will contact you to finalize project
					setup and invoicing as quickly as possible. Please allow 24-hrs to
					start processing the project order. Click 'User Name' in header Menu
					to see order history.
				</p>
				<br />

				<button className='--btn --btn-primary'>
					<Link to='/project-order-history'>View Project Status</Link>
				</button>
			</div>
		</section>
	);
};

export default ProjectRequestSuccess;
