import {
	collection,
	limit,
	onSnapshot,
	orderBy,
	query,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { db } from '../firebase/config';

const useFetchCollection = (collectionName) => {
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const getCollection = () => {
		setIsLoading(true);
		try {
			const docRef = collection(db, collectionName);
			// const docRef2 = collection(db, 'collect1', 'PartIDs', 'collect2');
			// console.log(docRef);

			const q = query(docRef, orderBy('createdAt', 'desc'), limit(200));
			onSnapshot(q, (snapshot) => {
				// console.log(snapshot.docs);
				const allData = snapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));
				// console.log('allData', allData);
				setData(allData);
				// console.log('data count:', allData.length);
				setIsLoading(false);
			});
		} catch (error) {
			setIsLoading(false);
			toast.error(error.message);
		}
	};

	useEffect(() => {
		getCollection();
	}, []);

	return { data, isLoading };
};

export default useFetchCollection;
