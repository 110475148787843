import React, { useEffect, useState } from 'react';
import styles from './StackList.module.scss';
import { BsFillGrid3X3GapFill } from 'react-icons/bs';
import { FaListAlt } from 'react-icons/fa';
import Search from '../../search/Search';
import StackItem from '../stackItem/StackItem';
import { useDispatch, useSelector } from 'react-redux';
import {
	FILTER_BY_SEARCH,
	selectFilteredStacks,
	SORT_STACKS,
} from '../../../redux/slice/filterSlice';
import Pagination from '../../pagination/Pagination';

const StackList = ({ stacks }) => {
	const [grid, setGrid] = useState(true);
	const [search, setSearch] = useState('');
	const [sort, setSort] = useState('latest');
	const filteredStacks = useSelector(selectFilteredStacks);

	// Pagination states
	const [currentPage, setCurrentPage] = useState(1);
	const [stacksPerPage] = useState(16);

	// Get Current Stacks
	const indexOfLastStack = currentPage * stacksPerPage;
	const indexOfFirstStack = indexOfLastStack - stacksPerPage;
	const currentStacks = filteredStacks.slice(
		indexOfFirstStack,
		indexOfLastStack
	);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(SORT_STACKS({ stacks, sort }));
	}, [dispatch, stacks, sort]);

	useEffect(() => {
		dispatch(FILTER_BY_SEARCH({ stacks, search }));
	}, [dispatch, stacks, search]);

	return (
		<div className={styles['stack-list']} id='stack'>
			<div className={styles.top}>
				<div className={styles.icons}>
					<BsFillGrid3X3GapFill
						size={22}
						color='orange'
						onClick={() => setGrid(true)}
					/>
					<FaListAlt size={24} color='white' onClick={() => setGrid(false)} />
					<p style={{ color: 'orange' }}>
						ID Collections:{' '}
						<b style={{ color: 'white' }}>{filteredStacks.length}</b>
					</p>
				</div>
				<div>
					<Search value={search} onChange={(e) => setSearch(e.target.value)} />
				</div>
				<div className={styles.sort}>
					<label>Sort by:</label>
					<select value={sort} onChange={(e) => setSort(e.target.value)}>
						<option value='latest'>Latest</option>
						<option value='a-z'>A - Z</option>
						<option value='z-a'>Z - A</option>
					</select>
				</div>
			</div>
			<div className={grid ? `${styles.grid}` : `${styles.list}`}>
				{stacks.length === 0 ? (
					<p>No Card found.</p>
				) : (
					<>
						{currentStacks.map((stack) => {
							return (
								<div key={stack.id}>
									<StackItem {...stack} grid={grid} stack={stack} />
								</div>
							);
						})}
					</>
				)}
			</div>
			<Pagination
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
				stacksPerPage={stacksPerPage}
				totalStacks={filteredStacks.length}
			/>
		</div>
	);
};

export default StackList;
