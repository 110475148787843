import { addDoc, collection, doc, setDoc, Timestamp } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { db, storage } from '../../../firebase/config';
import { selectStacks } from '../../../redux/slice/stackSlice';
import Card from '../../card/Card';
import Loader from '../../loader/Loader';
import styles from './CardAdd.module.scss';

// ✅ Format a date as MM/DD/YYYY (or any other format)
function padTo2Digits(num) {
	return num.toString().padStart(2, '0');
}

function formatDate(date) {
	return [
		padTo2Digits(date.getMonth() + 1),
		padTo2Digits(date.getDate()),
		date.getFullYear(),
	].join('-');
}

const retailer = [
	{ id: 1, name: 'Autozone' },
	{ id: 2, name: 'OReilly' },
	{ id: 3, name: 'Advance' },
	{ id: 4, name: 'Napa' },
	{ id: 5, name: 'Amazon' },
	{ id: 6, name: 'Walmart' },
	{ id: 7, name: 'CarID' },
	{ id: 8, name: 'RockAuto' },
];
const cardTypes = [
	{ id: 1, type: 'BRAND' },
	{ id: 2, type: 'CATEGORY' },
	// { id: 3, type: 'SAMPLE' },
];

const initialState = {
	ecommName: '', // Autozone, OReilly
	cardID: '', // alternators--zone
	cardName: '', // Alternators
	cardLabel: '', // Alternators
	stackLabel: '', // Alternators
	cardGroup: '', // Alternators / Starters
	cardEdition: '', // April 2023
	editDate: '', // 2023-04-20 date format without time
	createdAt: '', // timestamp when first created
	editAt: '', // timestamp when last updated
	previousDate: '', // 2023-03-20 date format without time last time shop done
	cardImage: '',
	scheduleDay: '', // 15 (PIDS only - for the 15th of the month)
	storeID: '', // 9824 (PIDS only - Store Number used to shop card each month use the same)
	storePostal: '', // 51421 (PIDS only - Store zipcode used to shop card each month use the same)
	cardAverage: 0, // Average Price of all IDs in card
	cardMode: 0, // Mode Price of all IDs in card
	countPids: 0, // Count of Unique IDs included in Card
	countBrands: 0, // Count unique 'brand' names in Card
	countIDsChange: 0, // 11 parts added to card since ... previous date
	countPriceIncreased: 0, // 11 parts in card increased price since ...previous date
	countPriceDecreased: 0, // 10 parts in card decreased price since ...previous date
	// countPriceSame: 0, // 8 parts in card decreased price since ...previous date
	countBrandsChange: 0, // 2 brands added, 2 brands removed, same brandsparts in card decreased price since ...previous date
	// countBrandsRemoved: 0, // 3 brands added, 2 brands removed, same brandsparts in card decreased price since ...previous date
	// countBrandsSame: 0, // 4 brands added, 2 brands removed, same brandsparts in card decreased price since ...previous date
	countZone: 0, // Autozone Count of Unique IDs included in same Card SET
	countOrly: 0, // OReilly Count of Unique IDs included in same Card SET
	countAdva: 0, // Advance Count of Unique IDs included in same Card SET
	countNapa: 0, // Napa Count of Unique IDs included in same Card SET
};

const CardAdd = () => {
	const { id } = useParams();
	const stacks = useSelector(selectStacks);
	const stackEdit = stacks.find((item) => item.id === id);

	const [stack, setStack] = useState(() => {
		const newState = detectForm(id, { ...initialState }, stackEdit);
		return newState;
	});

	const [uploadProgress, setUploadProgress] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();

	function detectForm(id, f1, f2) {
		if (id === 'ADD') {
			// console.log('F1');
			return f1;
		}
		// console.log('F2');
		return f2;
	}

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setStack({ ...stack, [name]: value });
	};

	const handleImageChange = (e) => {
		const file = e.target.files[0];
		// console.log(file);

		const storageRef = ref(storage, `card-image/${file.name}`);

		const uploadTask = uploadBytesResumable(storageRef, file);

		uploadTask.on(
			'state_changed',
			(snapshot) => {
				const progress =
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				setUploadProgress(progress);
			},
			(error) => {
				toast.error(error.message);
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
					setStack({ ...stack, cardImage: downloadURL });
					toast.success('Image uploaded successfully.');
				});
			}
		);
	};

	const addStack = async (e) => {
		e.preventDefault();
		setIsLoading(true);

		try {
			const uid = stack.cardID;
			await setDoc(doc(db, 'CARDS-ALL', uid), {
				ecommName: stack.ecommName,
				cardID: stack.cardID,
				cardName: stack.cardName,
				cardLabel: stack.cardName,
				stackLabel: stack.cardName,
				cardGroup: stack.cardGroup,
				cardEdition: stack.cardEdition,
				editDate: formatDate(Timestamp.now().toDate()),
				createdAt: Timestamp.now().toDate(),
				editAt: Timestamp.now().toDate(),
				previousDate: stack.previousDate,
				cardImage: stack.cardImage,
				scheduleDay: stack.scheduleDay,
				storeID: stack.storeID,
				storePostal: stack.storePostal,
				cardAverage: Number(stack.cardAverage),
				cardMode: Number(stack.cardMode),
				countPids: Number(stack.countPids),
				countBrands: Number(stack.countBrands),
				countIDsChange: Number(stack.countIDsChange),
				countPriceIncreased: Number(stack.countPriceIncreased),
				countPriceDecreased: Number(stack.countPriceDecreased),
				// countPriceSame: Number(stack.countPriceSame),
				countBrandsChange: Number(stack.countBrandsChange),
				// countBrandsAdded: Number(stack.countBrandsAdded),
				// countBrandsRemoved: Number(stack.countBrandsRemoved),
				// countBrandsSame: Number(stack.countBrandsSame),
				countZone: Number(stack.countZone),
				countOrly: Number(stack.countOrly),
				countAdva: Number(stack.countAdva),
				countNapa: Number(stack.countNapa),
			});

			setIsLoading(false);
			setUploadProgress(0);
			setStack({ ...initialState });

			toast.success('Card uploaded successfully.');
			navigate('/jackjrs/all-cards');
		} catch (error) {
			setIsLoading(false);
			toast.error(error.message);
		}
	};

	const editStack = (e) => {
		e.preventDefault();
		setIsLoading(true);

		try {
			setDoc(doc(db, 'CARDS-ALL', id), {
				ecommName: stack.ecommName,
				cardID: stack.cardID,
				cardName: stack.cardName,
				cardLabel: stack.cardName,
				stackLabel: stack.cardName,
				cardGroup: stack.cardGroup,
				cardEdition: stack.cardEdition,
				editDate: formatDate(Timestamp.now().toDate()),
				createdAt: stack.createdAt,
				editAt: Timestamp.now().toDate(),
				previousDate: stack.previousDate,
				cardImage: stack.cardImage,
				scheduleDay: stack.scheduleDay,
				storeID: stack.storeID,
				storePostal: stack.storePostal,
				cardAverage: Number(stack.cardAverage),
				cardMode: Number(stack.cardMode),
				countPids: Number(stack.countPids),
				countBrands: Number(stack.countBrands),
				countIDsChange: Number(stack.countIDsChange),
				countPriceIncreased: Number(stack.countPriceIncreased),
				countPriceDecreased: Number(stack.countPriceDecreased),
				// countPriceSame: Number(stack.countPriceSame),
				countBrandsChange: Number(stack.countBrandsChange),

				// countBrandsAdded: Number(stack.countBrandsAdded),
				// countBrandsRemoved: Number(stack.countBrandsRemoved),
				// countBrandsSame: Number(stack.countBrandsSame),

				countZone: Number(stack.countZone),
				countOrly: Number(stack.countOrly),
				countAdva: Number(stack.countAdva),
				countNapa: Number(stack.countNapa),
			});
			setIsLoading(false);
			toast.success('Card Edited Successfully');
			navigate('/jackjrs/all-cards');
		} catch (error) {
			toast.error(error.message);
		}
	};

	return (
		<>
			{isLoading && <Loader />}
			<div className={styles.stack}>
				<h2 style={{ color: 'orange' }}>
					{detectForm(id, 'Add Card', 'Edit Card')}
				</h2>

				<Card cardClass={styles.card}>
					<form onSubmit={detectForm(id, addStack, editStack)}>
						<label>Ecomm Name:</label>
						<select
							required
							name='ecommName'
							value={stack.ecommName}
							onChange={(e) => handleInputChange(e)}
						>
							<option value='' disabled>
								-- choose channel --
							</option>
							{retailer.map((x) => {
								return (
									<option key={x.id} value={x.name}>
										{x.name}
									</option>
								);
							})}
						</select>

						<label>Card ID:</label>
						<input
							type='text'
							placeholder='spark-plugs--zone...'
							// required
							name='cardID'
							value={stack.cardID}
							onChange={(e) => handleInputChange(e)}
						/>

						<label>Card Name:</label>
						<input
							type='text'
							placeholder='Spark Plugs (plural) ...'
							// required
							name='cardName'
							value={stack.cardName}
							onChange={(e) => handleInputChange(e)}
						/>

						<label>Set Group:</label>
						<input
							type='text'
							placeholder='Alternators / Starters ...'
							// required
							name='cardGroup'
							value={stack.cardGroup}
							onChange={(e) => handleInputChange(e)}
						/>

						<label>Edition:</label>
						<input
							type='text'
							placeholder='April 2023 ...'
							// required
							name='cardEdition'
							value={stack.cardEdition}
							onChange={(e) => handleInputChange(e)}
						/>

						<label>Previous Date:</label>
						<input
							type='text'
							placeholder='04-05-2023 ...'
							// required
							name='previousDate'
							value={stack.previousDate}
							onChange={(e) => handleInputChange(e)}
						/>

						<label>Card Image/Logo:</label>
						<Card cardClass={styles.group}>
							{uploadProgress === 0 ? null : (
								<div className={styles.progress}>
									<div
										className={styles['progress-bar']}
										style={{ width: `${uploadProgress}%` }}
									>
										{uploadProgress < 100
											? `Uploading ${uploadProgress}`
											: `Upload Complete ${uploadProgress}%`}
									</div>
								</div>
							)}

							<input
								type='file'
								accept='image/*'
								placeholder='cardImage...'
								name='image'
								onChange={(e) => handleImageChange(e)}
							/>

							{stack.stackLogo === '' ? null : (
								<input
									type='text'
									// required
									placeholder='cardImage'
									name='cardImage'
									value={stack.cardImage}
									disabled
								/>
							)}
						</Card>

						<label>Scheduled day of month to update:</label>
						<input
							type='number'
							placeholder='15...'
							// required
							name='scheduleDay'
							value={stack.scheduleDay}
							onChange={(e) => handleInputChange(e)}
						/>

						<label>Store Number:</label>
						<input
							type='text'
							placeholder='4315...'
							// required
							name='storeID'
							value={stack.storeID}
							onChange={(e) => handleInputChange(e)}
						/>

						<label>Store Postal:</label>
						<input
							type='text'
							placeholder='73110...'
							// required
							name='storePostal'
							value={stack.storePostal}
							onChange={(e) => handleInputChange(e)}
						/>

						<label>Card Average Price:</label>
						<input
							type='number'
							placeholder='cardAverage...'
							// required
							name='cardAverage'
							value={stack.cardAverage}
							onChange={(e) => handleInputChange(e)}
						/>

						<label>Card MODE Price:</label>
						<input
							type='number'
							placeholder='cardMode...'
							// required
							name='cardMode'
							value={stack.cardMode}
							onChange={(e) => handleInputChange(e)}
						/>

						<label>ID Count (unique):</label>
						<input
							type='number'
							placeholder='Count unique IDs...'
							// required
							name='countPids'
							value={stack.countPids}
							onChange={(e) => handleInputChange(e)}
						/>

						<label>Brands Count:</label>
						<input
							type='number'
							placeholder='brand names count...'
							// required
							name='countBrands'
							value={stack.countBrands}
							onChange={(e) => handleInputChange(e)}
						/>

						<label>Card Count Change:</label>
						<input
							type='number'
							placeholder='IDs Count change...'
							// required
							name='countIDsChange'
							value={stack.countIDsChange}
							onChange={(e) => handleInputChange(e)}
						/>

						<label>Count Price Increased:</label>
						<input
							type='number'
							placeholder='How many went UP on price...'
							// required
							name='countPriceIncreased'
							value={stack.countPriceIncreased}
							onChange={(e) => handleInputChange(e)}
						/>

						<label>Count Price Decreased:</label>
						<input
							type='number'
							placeholder='How many went DOWN on price...'
							// required
							name='countPriceDecreased'
							value={stack.countPriceDecreased}
							onChange={(e) => handleInputChange(e)}
						/>

						{/* <label>Count Price Same:</label>
						<input
							type='number'
							placeholder='How many had no change in price...'
							// required
							name='countPriceSame'
							value={stack.countPriceSame}
							onChange={(e) => handleInputChange(e)}
						/> */}

						<label>Brands Count Change:</label>
						<input
							type='number'
							placeholder='Count change since prev...'
							// required
							name='countBrandsChange'
							value={stack.countBrandsChange}
							onChange={(e) => handleInputChange(e)}
						/>

						<label>Autozone Count (same set):</label>
						<input
							type='number'
							placeholder='Autozone Card IDs count...'
							// required
							name='countZone'
							value={stack.countZone}
							onChange={(e) => handleInputChange(e)}
						/>

						<label>OReilly Count (same set):</label>
						<input
							type='number'
							placeholder='OReilly Card IDs count...'
							// required
							name='countOrly'
							value={stack.countOrly}
							onChange={(e) => handleInputChange(e)}
						/>

						<label>Advance Count (same set):</label>
						<input
							type='number'
							placeholder='Autozone Card IDs count...'
							// required
							name='countAdva'
							value={stack.countAdva}
							onChange={(e) => handleInputChange(e)}
						/>

						<label>Napa Count (same set):</label>
						<input
							type='number'
							placeholder='Autozone Card IDs count...'
							// required
							name='countNapa'
							value={stack.countNapa}
							onChange={(e) => handleInputChange(e)}
						/>

						<button className='--btn --btn-primary'>
							{detectForm(id, 'Save Card', 'Edit Card')}
						</button>
					</form>
				</Card>
			</div>
		</>
	);
};

export default CardAdd;
