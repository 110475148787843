import React, { useEffect, useState } from 'react';
import useFetchDocument from '../../../customHooks/useFetchDocument';
import styles from './ProjectOrderDetails.module.scss';
import spinnerImg from '../../../assets/spinner.jpg';
import { Link, useParams } from 'react-router-dom';
import ChangeProjectOrderStatus from '../changeProjectOrderStatus/ChangeProjectOrderStatus';

const ProjectOrderDetails = () => {
	const [projectOrder, setProjectOrder] = useState(null);
	const { id } = useParams();
	const { document } = useFetchDocument('Project--orders', id);

	useEffect(() => {
		setProjectOrder(document);
	}, [document]);

	return (
		<>
			<div className={styles.table}>
				<h2>Project Order - Details</h2>
				<div style={{ color: 'red' }}>
					<Link to='/jackjrs/project-orders'>
						&larr; Back To Project Orders
					</Link>
				</div>
				<br />
				{projectOrder === null ? (
					<img src={spinnerImg} alt='Loading...' style={{ width: '50px' }} />
				) : (
					<>
						<p>
							<b>Project Order ID : </b> {projectOrder.id}
						</p>
						<p>
							<b>Project Order Amount : </b> $
							{projectOrder.projectOrderAmount.toFixed(2)}
						</p>
						<p>
							<b>Project Order Status: </b> {projectOrder.projectOrderStatus}
						</p>
						<p>
							<b>Member Email : </b>
							<br />
							{projectOrder.userEmail}
							<br />
							<br />
						</p>
						<br />
						<table>
							<thead>
								<tr>
									<th>s/n</th>
									<th>Product</th>
									<th>ID Count</th>
									<th>Stack Price</th>
								</tr>
							</thead>
							<tbody>
								{projectOrder.projectsItems.map((cart, index) => {
									const {
										id,
										stackID,
										stackPrice,
										stackLogo,
										stackCount,
										projectQuantity,
									} = cart;
									return (
										<tr key={index}>
											<td>
												<b>{index + 1}</b>
											</td>
											<td>
												<p>
													<b>{stackID}</b>
												</p>
												<img
													src={stackLogo}
													alt={stackID}
													style={{ width: '100px' }}
												/>
											</td>
											<td>{stackCount}</td>
											<td>{stackPrice}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</>
				)}
				<ChangeProjectOrderStatus projectOrder={projectOrder} id={id} />
			</div>
		</>
	);
};

export default ProjectOrderDetails;
