import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useFetchCollection from '../../../customHooks/useFetchCollection';

import {
	selectProjectOrderHistory,
	STORE_PROJECT_ORDERS,
} from '../../../redux/slice/projectOrderSlice';
import { selectProjectsTotalAmountDownload } from '../../../redux/slice/projectsSlice';
import Loader from '../../loader/Loader';
import styles from './ProjectOrders.module.scss';

const ProjectOrders = () => {
	const { data, isLoading } = useFetchCollection('Project--orders');
	const projectsTotalAmountDownload = useSelector(
		selectProjectsTotalAmountDownload
	);
	// const projectOrders = useSelector(selectProjectOrderHistory);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(STORE_PROJECT_ORDERS(data));
	}, [dispatch, data]);

	const handleClick = (id) => {
		navigate(`/jackjrs/card-order-details/${id}`);
	};

	return (
		<>
			<div className={styles.projectOrder}>
				<h2>Your Project Order History</h2>
				<p>
					Open an project Order to <b>Change project Order status</b>
				</p>
				<br />
				<>
					{isLoading && <Loader />}
					<div className={styles.table}>
						{data.length === 0 ? (
							<p>No project Order found</p>
						) : (
							<table>
								<thead>
									<tr>
										<th>s/n</th>
										<th>Date</th>
										<th>Project Order ID</th>
										<th>Project Order Amount</th>
										<th>Project Order Status</th>
									</tr>
								</thead>
								<tbody>
									{data.map((projectOrder, index) => {
										const {
											id,
											projectOrderDate,
											projectOrderTime,
											projectsTotalAmountDownload,
											projectOrderStatus,
										} = projectOrder;
										return (
											<tr key={id} onClick={() => handleClick(id)}>
												<td>{index + 1}</td>
												<td>
													{projectOrderDate} at {projectOrderTime}
												</td>
												<td>{id}</td>
												<td>
													{'$'}
													{projectsTotalAmountDownload}
												</td>
												<td>
													<p
														className={
															projectOrderStatus !== 'Ready'
																? `${styles.pending}`
																: `${styles.ready}`
														}
													>
														{projectOrderStatus}
													</p>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						)}
					</div>
				</>
			</div>
		</>
	);
};

export default ProjectOrders;
