import React, { useEffect } from 'react';
import InfoBox from '../../infoBox/InfoBox';
import styles from './Dash.module.scss';
import { AiFillDollarCircle } from 'react-icons/ai';
import { BsCart4 } from 'react-icons/bs';
import { FaCartArrowDown } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
// import {
// 	selectAutoparts,
// 	STORE_AUTOPARTS,
// } from '../../../redux/slice/old/autopartSlice';
// import {
// 	CALC_TOTAL_ORDER_AMOUNT,
// 	selectOrderHistory,
// 	selectTotalOrderAmount,
// 	STORE_ORDERS,
// } from '../../../redux/slice/old/orderSlice';
import useFetchCollection from '../../../customHooks/useFetchCollection';
import Chart from '../../chart/Chart';

//Icons
const earningIcon = <AiFillDollarCircle size={30} color='#b624ff' />;
const autopartIcon = <BsCart4 size={30} color='#1f93ff' />;
const ordersIcon = <FaCartArrowDown size={30} color='red' />;

const Dash = () => {
	return (
		<div className={styles.home}>
			<h2>Admin Home</h2>
			<div className={styles['info-box']}>
				<InfoBox
					cardClass={`${styles.card} ${styles.card1}`}
					title={'Earnings'}
					count='{totalOrderAmount}'
					icon={earningIcon}
				/>
				<InfoBox
					cardClass={`${styles.card} ${styles.card2}`}
					title={'Autoparts'}
					count='{autoparts.length}'
					icon={autopartIcon}
				/>
				<InfoBox
					cardClass={`${styles.card} ${styles.card3}`}
					title={'Orders'}
					count='{orders.length}'
					icon={ordersIcon}
				/>
			</div>
			<div>
				<Chart />
			</div>
		</div>
	);
};

export default Dash;
