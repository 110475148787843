import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Navbar from '../../components/jackjrs/navbar/Navbar';
import ProjectOrders from '../../components/jackjrs/projectOrders/ProjectOrders';
import styles from './Jackjrs.module.scss';
import ProjectOrderDetails from '../../components/jackjrs/projectOrderDetails/ProjectOrderDetails';
import Dash from '../../components/jackjrs/dash/Dash';
import StackAdd from '../../components/jackjrs/stackAdd/StackAdd';
import StacksView from '../../components/jackjrs/stacksView/StacksView';
import CardsView from '../../components/jackjrs/cardsView/CardsView';
import CardAdd from '../../components/jackjrs/cardAdd/CardAdd';

const Jackjrs = () => {
	return (
		<div className={styles.jackjrs}>
			<div className={styles.navbar}>
				<Navbar />
			</div>
			<div className={styles.content}>
				<Routes>
					<Route path='dash' element={<Dash />} />
					{/* <Route path='add-partid/:id' element={<PartidAdd />} />
					<Route path='all-partids/:id' element={<PartidsView />} /> */}

					{/* <Route path='add-card/:id' element={<CardAdd />} /> */}
					{/* <Route path='all-cards' element={<CardsView />} /> */}
					<Route path='add-card/:id' element={<CardAdd />} />
					<Route path='all-cards' element={<CardsView />} />
					{/* <Route path='all-projects' element={<ProjectsView />} /> */}

					<Route path='card-orders' element={<ProjectOrders />} />
					<Route
						path='card-order-details/:id'
						element={<ProjectOrderDetails />}
					/>
					{/* <Route path='ufosBots' element={<UfosBots />} /> */}
				</Routes>
			</div>
		</div>
	);
};

export default Jackjrs;
