import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	partids: [],
};

const partidSlice = createSlice({
	name: 'partid',
	initialState,
	reducers: {
		STORE_PARTIDS(state, action) {
			state.partids = action.payload.partids;
			// console.log(action.payload.partids);
		},
		GET_PROJECT_LIST(state, action) {
			const { partids } = action.payload;
		},
	},
});

export const { STORE_PARTIDS } = partidSlice.actions;

export const selectPartids = (state) => state.partid.partids;

export default partidSlice.reducer;
