import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	comments: {},
};

const projectRequestSlice = createSlice({
	name: 'projectRequest',
	initialState,
	reducers: {
		SAVE_COMMENTS(state, action) {
			state.comments = action.payload;
		},
	},
});

export const { SAVE_COMMENTS } = projectRequestSlice.actions;
export const selectComments = (state) => state.projectRequest.comments;

export default projectRequestSlice.reducer;
