import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import StackCard from '../stackCard/StackCard';
import styles from './StackItem.module.scss';

import { GiRadarSweep } from 'react-icons/gi';
import { Divider, IconButton, Paper, Stack } from '@mui/material';
import {
	ADD_TO_PROJECTS,
	CALCULATE_TOTAL_QUANTITY,
} from '../../../redux/slice/projectsSlice';

const StackItem = ({
	grid,
	id,
	ecommName,
	editDate,
	countPids,
	cardImage,
	stackID,
	stackLabel,
}) => {
	const dispatch = useDispatch();

	const shortenText = (text, n) => {
		if (text.length > n) {
			const shortenText = text.substring(0, n).concat('...');
			return shortenText;
		}
		return text;
	};

	const addToProjects = (stack) => {
		dispatch(ADD_TO_PROJECTS(stack));
		dispatch(CALCULATE_TOTAL_QUANTITY());
	};

	let detectEcomm = styles.stacklogo;
	if (ecommName === 'Autozone') detectEcomm = styles.stacklogozone;
	if (ecommName === 'OReilly') detectEcomm = styles.stacklogoorly;
	if (ecommName === 'Napa') detectEcomm = styles.stacklogonapa;
	if (ecommName === 'Advance') detectEcomm = styles.stacklogoadva;

	return (
		<StackCard cardClass={grid ? `${styles.grid}` : `${styles.list}`}>
			<Link to={`/stack-details/${id}`}>
				<div className={detectEcomm}>
					<img src={cardImage} alt={stackID} />
				</div>

				<div className={styles.content}>
					<div className={styles.details}>
						<div className={styles.ecomm}></div>
						<div className={styles.ecomm}>
							<h4>{ecommName}</h4>
						</div>
						<div className={styles.label}>
							<h3>{shortenText(stackLabel, 35)}</h3>
						</div>
						<div className={styles.count}>
							<Stack
								p={1}
								direction='row'
								divider={<Divider orientation='vertical' flexItem />}
								spacing={2}
							>
								{'IDs : '}

								<h3 style={{ color: 'ivory' }}>
									<b>{Number(`${countPids}`).toFixed(0)}</b>
								</h3>
								<GiRadarSweep size={22} color='white' />
							</Stack>
							<h6>Last Updated: {editDate}</h6>
						</div>
					</div>
				</div>
			</Link>
			<div className={styles.iconrow}></div>
		</StackCard>
	);
};

export default StackItem;
