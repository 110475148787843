import React, { useEffect, useState } from 'react';
import styles from './ProjectOrderDetails.module.scss';
import spinnerImg from '../../assets/spinner.jpg';
import { Link, useParams } from 'react-router-dom';
import useFetchDocument from '../../customHooks/useFetchDocument';

const ProjectOrderDetails = () => {
	const [projectOrder, setProjectOrder] = useState(null);
	const { id } = useParams();
	const { document } = useFetchDocument('Project--orders', id);
	useEffect(() => {
		setProjectOrder(document);
	}, [document]);

	// console.log('id', id);
	// console.log('projectOrder', projectOrder);

	return (
		<div className={`container ${styles.table}`}>
			<h2>Project Order Details</h2>
			<div>
				<Link to='/project-order-history' style={{ color: 'darkred' }}>
					&larr; Back to Project Orders
				</Link>
			</div>
			<br />
			{projectOrder === null ? (
				<img src={spinnerImg} alt='Loading...' styles={{ width: '50px' }} />
			) : (
				<>
					<div>
						<p>
							<b>Project Order ID: </b>
							{projectOrder.id}
						</p>
					</div>

					{/* <p>
						<b>{'Project Order Amount: '}</b>${' '}
						{projectOrder.projectOrderAmount.toFixed(2)}
					</p> */}
					<p>
						<b>Project Order Status: </b> {projectOrder.projectOrderStatus}
					</p>
					<br />
					<table>
						<thead>
							<tr>
								<th>s/n</th>
								<th>Product</th>
								<th>Count</th>
								<th>Retailer</th>
								<th>Edition</th>
								{/* <th>Action</th> */}
							</tr>
						</thead>
						<tbody>
							{projectOrder.projectsItems.map((cart, index) => {
								const {
									id,
									stackID,
									stackPrice,
									stackLogo,
									projectsQuantity,
									stackLabel,
									stackCount,
									ecommName,
									stackEdition,
								} = cart;
								return (
									<tr key={id}>
										<td>
											<b>{index + 1}</b>
										</td>
										<td>
											<p>
												<b>{stackLabel}</b>
											</p>
											<img
												src={stackLogo}
												alt={stackID}
												style={{ width: '100px' }}
											/>
										</td>
										<td>{stackCount.toFixed(0)}</td>
										<td>{ecommName}</td>
										<td>{stackEdition}</td>

										{/* <td>{(stackPrice * projectsQuantity).toFixed(2)}</td> */}
										{/* <td className={styles.icon}>
											<Link to={`/sample--download/`}>
												<button className='--btn --btn-primary --btn-block'>
													View Collection
												</button>
											</Link>
											<br />
											<Link to={`/review-product/${id}`}>
												<button className='--btn --btn-primary --btn-block'>
													Review Collection
												</button>
											</Link>
										</td> */}
									</tr>
								);
							})}
						</tbody>
					</table>
				</>
			)}
		</div>
	);
};

export default ProjectOrderDetails;
