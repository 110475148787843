import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectUserID, selectUserName } from '../../redux/slice/authSlice';
import styles from './ReviewProducts.module.scss';
import StarsRating from 'react-star-rate';
import { addDoc, collection, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { toast } from 'react-toastify';
import useFetchDocument from '../../customHooks/useFetchDocument';
import spinnerImg from '../../assets/spinner.jpg';
import Card from '../../components/card/Card';

const ReviewProducts = () => {
	const [rate, setRate] = useState(0);
	const [review, setReview] = useState('');
	const [product, setProduct] = useState(null);
	const { id } = useParams();
	const { document } = useFetchDocument('partids', id);
	const userID = useSelector(selectUserID);
	const userName = useSelector(selectUserName);

	useEffect(() => {
		setProduct(document);
	}, [document]);

	const submitReview = (e) => {
		e.preventDefault();

		const today = new Date();
		const date = today.toDateString();
		const reviewConfig = {
			userID,
			userName,
			productID: id,
			rate,
			review,
			reviewDate: date,
			createdAt: Timestamp.now().toDate(),
		};
		try {
			addDoc(collection(db, 'reviews'), reviewConfig);
			toast.success('Review submitted successfully');
			setRate(0);
			setReview('');
		} catch (error) {
			toast.error(error.message);
		}
	};

	return (
		<section>
			<div className={`container ${styles.review}`}>
				<h2>Review Products</h2>
				{product === null ? (
					<img src={spinnerImg} alt='Loading...' style={{ width: '50px' }} />
				) : (
					<>
						<p>
							<b>Product name:</b> {product.name}
						</p>
						<img
							src={product.imageURL}
							alt={product.name}
							style={{ width: '100px' }}
						/>
					</>
				)}

				<Card cardClass={styles.card}>
					<form onSubmit={(e) => submitReview(e)}>
						<label>Rating:</label>
						<StarsRating
							value={rate}
							onChange={(rate) => {
								setRate(rate);
							}}
						/>
						<label>Review</label>
						<textarea
							value={review}
							required
							onChange={(e) => setReview(e.target.value)}
							cols='30'
							rows='10'
						></textarea>
						<button type='submit' className='--btn --btn-primary'>
							Submit Review
						</button>
					</form>
				</Card>
			</div>
		</section>
	);
};

export default ReviewProducts;
