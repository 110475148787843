import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	projectOrderHistory: [],
	totalProjectOrderAmount: null,
};

const projectOrderSlice = createSlice({
	name: 'projectOrders',
	initialState,
	reducers: {
		STORE_PROJECT_ORDERS(state, action) {
			state.projectOrderHistory = action.payload;
		},
		CALC_TOTAL_PROJECT_ORDER_AMOUNT(state, action) {
			const array = [];
			state.projectOrderHistory.map((item) => {
				const { projectOrderAmount } = item;
				return array.push(projectOrderAmount);
			});
			const totalAmount = array.reduce((a, b) => {
				return a + b;
			}, 0);
			state.totalProjectOrderAmount = totalAmount;
		},
	},
});

export const { STORE_PROJECT_ORDERS, CALC_TOTAL_PROJECT_ORDER_AMOUNT } =
	projectOrderSlice.actions;

export const selectProjectOrderHistory = (state) =>
	state.projectOrders.projectOrderHistory;
export const selectTotalProjectOrderAmount = (state) =>
	state.projectOrders.totalProjectOrderAmount;

export default projectOrderSlice.reducer;
