import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
	ADD_TO_PROJECTS,
	CALCULATE_SUBTOTAL_DOWNLOAD,
	CALCULATE_SUBTOTAL,
	CALCULATE_TOTAL_QUANTITY,
	CLEAR_PROJECTS,
	DECREASE_PROJECTS,
	REMOVE_FROM_PROJECTS,
	SAVE_URL,
	selectProjectsItems,
	selectProjectsTotalAmount,
	selectProjectsTotalQuantity,
	selectProjectsTotalAmountDownload,
} from '../../redux/slice/projectsSlice';
import styles from './Projects.module.scss';
import { FaTrashAlt } from 'react-icons/fa';
import Card from '../../components/card/Card';
import { selectIsLoggedIn } from '../../redux/slice/authSlice';

const Projects = () => {
	const projectsItems = useSelector(selectProjectsItems);
	const projectsTotalAmount = useSelector(selectProjectsTotalAmount);
	const projectsTotalAmountDownload = useSelector(
		selectProjectsTotalAmountDownload
	);
	const projectsTotalQuantity = useSelector(selectProjectsTotalQuantity);
	const isLoggedIn = useSelector(selectIsLoggedIn);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const increaseProjects = (projects) => {
		dispatch(ADD_TO_PROJECTS(projects));
	};
	const decreaseProjects = (projects) => {
		dispatch(DECREASE_PROJECTS(projects));
	};
	const removeFromProjects = (projects) => {
		dispatch(REMOVE_FROM_PROJECTS(projects));
	};
	const clearProjects = () => {
		dispatch(CLEAR_PROJECTS());
	};

	useEffect(() => {
		dispatch(CALCULATE_SUBTOTAL());
		dispatch(CALCULATE_SUBTOTAL_DOWNLOAD());
		dispatch(CALCULATE_TOTAL_QUANTITY());
		dispatch(SAVE_URL(''));
	}, [dispatch, projectsItems]);

	const url = window.location.href;
	const checkout = () => {
		if (isLoggedIn) {
			navigate('/project-request-details');
		} else {
			dispatch(SAVE_URL(url));
			navigate('/login');
		}
	};

	return (
		<section>
			<div className={`container ${styles.table}`}>
				<h2>Project Request (Projects)</h2>
				{projectsItems.length === 0 ? (
					<>
						<p>Your Request is currently Empty.</p>
						<br />
						<div>
							<Link to='/home'>&larr; Continue exploring</Link>
						</div>
					</>
				) : (
					<>
						<table>
							<thead>
								<tr>
									<th>s/n</th>
									<th>Retailer</th>
									<th>Product</th>
									<th>Type</th>
									{/* <th>Project Price $</th> */}
									<th>Downloads</th>
									{/* <th>Total</th> */}
									<th>Actions</th>
								</tr>
							</thead>
							<tbody>
								{projectsItems.map((projects, index) => {
									const {
										id,
										ecommName,
										stackCount,
										stackLogo,
										stackID,
										stackPrice,
										projectType,
										stackSector,
										stackBrand,
										projectsQuantity,
									} = projects;
									return (
										<tr key={index}>
											<td>{index + 1}</td>
											<td>
												<p>
													<b>{ecommName}</b>
												</p>
											</td>
											<td>
												<p>
													<b>{stackID}</b>
												</p>
												<img
													src={stackLogo}
													alt={stackID}
													style={{ width: '100px' }}
												/>
											</td>
											<td>{projectType}</td>
											{/* <td>$ {Number(stackPrice).toFixed(2)}</td> */}
											{/* <td>
												<div className={styles.count}>
													<button
														className='--btn'
														onClick={() => decreaseProjects(projects)}
													>
														-
													</button>
													<p>
														<b>{projectsQuantity}</b>
													</p>
													<button
														className='--btn'
														onClick={() => increaseProjects(projects)}
													>
														+
													</button>
												</div>
											</td> */}
											<td>
												<p>
													<b>{stackCount}</b>
												</p>
											</td>
											{/* <td>{(stackPrice * projectsQuantity).toFixed(2)}</td> */}
											<td className={styles.icons}>
												<FaTrashAlt
													size={18}
													color='red'
													onClick={() => removeFromProjects(projects)}
												/>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
						<div className={styles.summary}>
							<button className='--btn --btn-danger' onClick={clearProjects}>
								Clear Projects
							</button>
							<div className={styles.checkout}>
								<div>
									<Link to='/home'>&larr; Continue exploring</Link>
								</div>
								<br />
								<Card cardClass={styles.card}>
									<p>
										{'Projects item(s):  '}
										<span style={{ color: 'red' }}>
											{projectsTotalQuantity}
										</span>
									</p>
									<div className={styles.text}>
										<h4>Total:</h4>
										<h3>{`${projectsTotalAmountDownload}`}</h3>
									</div>
									{/* <div className={styles.text}>
										<h4>Subtotal Download:</h4>
										<h3>{`$${projectsTotalAmountDownload.toFixed(2)}`}</h3>
									</div> */}
									{/* <p>Total Downloads calculated at checkout</p> */}
									<button
										className='--btn --btn-primary --btn-block'
										onClick={checkout}
									>
										Continue
									</button>
								</Card>
							</div>
						</div>
					</>
				)}
			</div>
		</section>
	);
};

export default Projects;
