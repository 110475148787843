import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	selectProjectsItems,
	selectProjectsTotalAmount,
	selectProjectsTotalAmountDownload,
	selectProjectsTotalQuantity,
} from '../../redux/slice/projectsSlice';
import Card from '../card/Card';
import styles from './ProjectRequestSummary.module.scss';

const ProjectRequestSummary = () => {
	const projectsItems = useSelector(selectProjectsItems);
	const projectsTotalAmount = useSelector(selectProjectsTotalAmount);
	const projectsTotalQuantity = useSelector(selectProjectsTotalQuantity);
	const projectsTotalAmountDownload = useSelector(
		selectProjectsTotalAmountDownload
	);

	return (
		<div>
			<h3>Project Summary</h3>
			<div>
				{projectsItems.length === 0 ? (
					<>
						<p>No item in your projects.</p>
						<button className='--btn --btn-primary'>
							<Link to='/home'>Back To Shop</Link>
						</button>
					</>
				) : (
					<div>
						<p>
							{/* <b>{`Projects item(s): ${projectsTotalAmountDownload}`}</b> */}
						</p>
						<div className={styles.text}>
							<h4>Total Downloads:</h4>
							<h3> {projectsTotalAmountDownload.toFixed(0)}</h3>
						</div>
						{projectsItems.map((item, index) => {
							const {
								id,
								projectType,
								stackCount,
								stackPrice,
								projectsQuantity,
							} = item;
							return (
								<Card key={index} cardClass={styles.card}>
									<h4>{id}</h4>
									<p>ID Count: {stackCount}</p>
									<p>Report Type: {projectType}</p>
									{/* <p>Collection Price: $ {stackPrice.toFixed(2)}</p> */}
								</Card>
							);
						})}
					</div>
				)}
			</div>
		</div>
	);
};

export default ProjectRequestSummary;
