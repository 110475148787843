import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/loader/Loader';
import useFetchCollection from '../../customHooks/useFetchCollection';
import { selectUserID } from '../../redux/slice/authSlice';
import {
	selectProjectOrderHistory,
	STORE_PROJECT_ORDERS,
} from '../../redux/slice/projectOrderSlice';

import styles from './ProjectOrderHistory.module.scss';

const ProjectOrderHistory = () => {
	const { data, isLoading } = useFetchCollection('Project--orders');
	// console.log('data', data);
	const userID = useSelector(selectUserID);
	// console.log('userID', userID);

	// const projectOrders = useSelector(selectProjectOrderHistory);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(STORE_PROJECT_ORDERS(data));
	}, [dispatch, data]);

	const handleClick = (id) => {
		navigate(`/project-order-details/${id}`);
	};

	const filteredProjectOrders = data.filter((data) => data.userID === userID);
	console.log('filteredProjectOrders', filteredProjectOrders);

	return (
		<section>
			<div className={`container ${styles.projectOrder}`}>
				<h2>Your Project History</h2>
				<p>
					Open an Project Order to leave a <b>Product Review</b>
				</p>
				<br />
				<>
					{isLoading && <Loader />}
					<div className={styles.table}>
						{filteredProjectOrders.length === 0 ? (
							<p>No Project Order found</p>
						) : (
							<table>
								<thead>
									<tr>
										<th>s/n</th>
										<th>Date</th>
										<th>Project ID</th>
										{/* <th>Project Amount</th> */}
										<th>Project Status</th>
									</tr>
								</thead>
								<tbody>
									{filteredProjectOrders.map((projectOrder, index) => {
										const {
											id,
											projectOrderDate,
											projectOrderTime,
											projectOrderAmount,
											projectOrderStatus,
										} = projectOrder;
										return (
											<tr key={index} onClick={() => handleClick(id)}>
												<td>{index + 1}</td>
												<td>
													{projectOrderDate} at {projectOrderTime}
												</td>
												<td>{id}</td>
												{/* <td>$ {projectOrderAmount.toFixed(2)}</td> */}
												<td>
													<p
														className={
															projectOrderStatus !== 'Delivered'
																? `${styles.pending}`
																: `${styles.delivered}`
														}
													>
														{projectOrderStatus}
													</p>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						)}
					</div>
				</>
			</div>
		</section>
	);
};

export default ProjectOrderHistory;
